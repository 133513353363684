<template>
  <div class="publishJob">
    <div class="page_title">
      发布招工
    </div>
    <div class="page_content">
      <div class="form_box">
        <el-form :model="sumitObj" :rules="rules" ref="sumitObj" label-width="100px" class="demo-sumitObj">
          <el-form-item label="标题:" prop="name">
            <el-input v-model="sumitObj.name" placeholder="请输入姓名"></el-input>
          </el-form-item>
          <el-form-item label="所招岗位:" prop="category_id">
            <el-cascader v-model="sumitObj.category_id" collapse-tags :props="props" :options="$store.state.categoryConfig.job" placeholder="请选择岗位">
            </el-cascader>
          </el-form-item>
          <el-form-item label="结算方式:" prop="calculate_method">
            <el-radio-group v-model="sumitObj.calculate_method">
              <el-radio label="1">面议</el-radio>
              <el-radio label="2">日结</el-radio>
              <el-radio label="3">月结</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="薪资:" prop="salary">
            <el-input v-model="sumitObj.salary" placeholder="请输入金额" type="number">
              <span slot="suffix">元/日</span>
            </el-input>
          </el-form-item>
          <el-form-item label="招工标签:" prop="job_tag_ids">
            <div class="job_type">
              <div :class="['job_type_item',sumitObj.job_tag_ids.indexOf(item.id)!=-1?'active_type_item':'']"
                v-for="item in $store.state.categoryConfig.job_tag" :key="item.name" @click="changejob(item.id)">
                {{  item.name}}
              </div>
            </div>
          </el-form-item>
          <el-form-item label="联系人:" prop="contact">
            <el-input v-model="sumitObj.contact" placeholder="请输入联系人姓名"></el-input>
          </el-form-item>
          <el-form-item label="联系电话:" prop="contact_phone">
            <el-input v-model="sumitObj.contact_phone" placeholder="请输入联系电话"></el-input>
          </el-form-item>
          <el-form-item label="工作地区:" prop="area_code">
            <RegionSelect :value="sumitObj.area_code" @changeRegion="changeRegion"></RegionSelect>
          </el-form-item>
          <el-form-item label="详细地址:" prop="address">
            <el-input v-model="sumitObj.address" placeholder="请输入详细地址"></el-input>
          </el-form-item>
          <div class="page_area_title">
            <span class="must_point">*</span> 招工描述
          </div>
          <div class="text_area_box">
            <el-form-item prop="desc">
              <el-input type="textarea" v-model="sumitObj.desc" placeholder="可简单介绍一下招工要求及岗位信息"></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <button class="blue_btn" @click="submitForm('sumitObj')">发布招工</button>
    </div>
  </div>
</template>
  
<script>
import RegionSelect from '../../../components/regionSelect.vue'
import { validatePass } from '@/utils';

export default {
  components: {
    RegionSelect,
  },
  data () {
    return {
      pageAuth: 'publish_employee',
      props: { value: 'id', label: 'name', children: 'child' },
      sumitObj: {
        name: '',
        contact: '',
        contact_phone: '',
        calculate_method: '',
        salary: '',
        job_tag_ids: [],
        category_id: '',
        area: '',
        area_code: [],
        address: '',
        desc: '',
      },

      rules: {
        name: [
          { required: true, message: '请输入标题', trigger: 'blur' },
        ],
        contact: [
          { required: true, message: '请输入联系人', trigger: 'blur' },
        ],
        contact_phone: [
          { required: true, message: '请输入联系方式', trigger: 'blur' },
          { validator: validatePass, trigger: 'blur' },
        ],
        salary: [
          { required: true, message: '请输入金额', trigger: 'blur' },
        ],
        calculate_method: [
          { required: true, message: '请选择结算方式', trigger: 'change' }
        ],
        category_id: [
          { required: true, message: '所选岗位', trigger: 'change' }
        ],
        job_tag_ids: [
          { required: true, message: '请选择招工标签', trigger: 'blur' }
        ],
        area_code: [
          { required: true, message: '请选择项目地区', trigger: 'blur' }
        ],
        desc: [
          { required: true, message: '请输入招工秒速', trigger: 'blur' },
        ],
      },

      curJobTags: [],
    }
  },
  methods: {
    changejob (val) {
      if (this.sumitObj.job_tag_ids.indexOf(val) == -1) {
        this.sumitObj.job_tag_ids.push(val);
      } else {
        this.sumitObj.job_tag_ids = this.sumitObj.job_tag_ids.filter(item => item != val);
      }
    },
    changeRegion (val) {
      console.log(val, 'sasa');
      this.sumitObj.area_code = val.area_code;
      this.sumitObj.area = val.area;
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            ...this.sumitObj
          }
          params.category_id = params.category_id[1];
          params.area_code = `${this.sumitObj.area_code[0]}/${this.sumitObj.area_code[1]}`;
          params.job_tag_ids += '';
          this.$api.publishJob(params).then(res => {
            if (res.code == 1) {
              this.$message.success(res.msg);
              this.sumitObj = {
                name: '',
                contact: '',
                contact_phone: '',
                calculate_method: '',
                salary: '',
                job_tag_ids: [],
                category_id: '',
                area: '',
                area_code: [],
                address: '',
                desc: '',
              }
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm (formName) {
      this.$refs[formName].resetFields();
    }
  },
  mounted () {
    this.checkMemberDealAuth(this.pageAuth);
  },
  watch: {

  },
}
</script>
  
<style lang='scss' scoped>
.publishJob {
  background-color: #fff;
  padding: 26px;
  .page_title {
    padding-bottom: 12px;
    border-bottom: 1px solid #e5e5e5;
    font-size: 22px;
    font-weight: 500;
    color: #2f2f2f;
    margin-bottom: 30px;
  }
  .job_type {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .job_type_item {
      width: 76px;
      height: 31px;
      background: #f6f6f6;
      border-radius: 3px 3px 3px 3px;
      opacity: 1;
      border: 1px solid #f6f6f6;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      box-sizing: border-box;
      margin-right: 7px;
      cursor: pointer;
    }
    .active_type_item {
      background: #f3f5ff;
      opacity: 1;
      border: 1px solid #3f63ff;
      color: #3f63ff;
    }
  }
  .tips {
    margin: 0 0 26px;
  }
  .page_content {
    .page_area_title {
      font-size: 18px;
      font-weight: 500;
      color: #333333;
      margin-bottom: 13px;
    }

    /deep/.el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100%;
    }
    .form_box {
      margin-bottom: 23px;
      /deep/ .el-form-item {
        label {
          width: 110px !important;
        }
        .el-form-item__content {
          margin-left: 110px !important;
        }
      }
      /deep/ .el-select {
        width: 100%;
      }
      .upload_img_box {
        margin-bottom: 50px;
        /deep/ .el-form-item .el-form-item__content {
          margin-left: 0 !important;
        }
        .upload_img_tags_text {
          font-size: 12px;
          font-weight: 400;
          color: #999999;
          margin-bottom: 10px;
        }
      }
      .text_area_box {
        /deep/ .el-form-item .el-form-item__content {
          margin-left: 0 !important;
        }
        /deep/ .el-textarea textarea {
          height: 106px;
        }
      }
    }
  }
}
</style>