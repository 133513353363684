import mReg from '../common/js/reg'

export function validatePass (rule, value, callback) {
  if (value === "") {
    callback(new Error("请输入联系方式"));
  } else if (!mReg.isPhone.test(value)) {
    callback(
      new Error(
        "请输入正确的联系方式！"
      )
    );
  } else {
    callback();
  }
}